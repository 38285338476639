<template>
  <div class="modal-content">
    <form class="form-relative" novalidate @submit.prevent="createFilter">
      <div ref="content" class="form-in-scroll" style="scrollbar-gutter: stable both-edges;">
        <ErrorMessage
          v-if="errorMessage"
          :error-message="errorMessage"
        ></ErrorMessage>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="form-control-label"
                >Название фильтра<span>*</span></label
              >
              <input
                :value="formData.name"
                @change="updateParameter($event.target.value, 'name')"
                @input="removeSpaces($event)"
                type="text"
                class="form-control form-control-light"
                :class="{ 'is-invalid': $v.formData.name.$error }"
                placeholder="Название фильтра"
                required
                data-v-step="filter-name-input"
                ref="filterNameInput"
              />
              <div
                v-if="$v.formData.name.$dirty && !$v.formData.name.required"
                class="invalid-feedback"
              >
                Поле обязательно для заполнения
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group form-group-budget">
              <label class="form-control-label">Бюджет</label>
              <div class="row">
                <div class="col-6 budget">
                  <input
                    type="text"
                    class="form-control form-control-light"
                    :class="{ 'is-invalid': isBudgetInvalid }"
                    placeholder="от"
                    :value="formData.budgetFrom"
                    @focus="isBudgetInvalid = false"
                    @change="updateParameter($event.target.value, 'budgetFrom')"
                    v-mask="{
                      alias: 'decimal',
                      inputMode: 'numeric',
                      groupSeparator: ' ',
                      placeholder: '',
                      showMaskOnHover: false,
                      rightAlign: false,
                    }"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control form-control-light"
                    :class="{ 'is-invalid': isBudgetInvalid }"
                    placeholder="до"
                    :value="formData.budgetTo"
                    @focus="isBudgetInvalid = false"
                    @change="updateParameter($event.target.value, 'budgetTo')"
                    v-mask="{
                      alias: 'decimal',
                      inputMode: 'numeric',
                      groupSeparator: ' ',
                      placeholder: '',
                      showMaskOnHover: false,
                      rightAlign: false,
                    }"
                  />
                </div>
              </div>
              <div v-if="this.isBudgetInvalid" class="invalid-feedback">
                Нижняя граница заданного бюджета не может превышать верхнюю
                границу
              </div>
            </div>
          </div>
        </div>
        <div class="form-places" v-if="placesTypes.length > 0">
          <label class="places-item" v-for="place in placesTypes" :key="place[trackBy]">
            <input
              type="checkbox"
              :value="place[trackBy]"
              :checked="isChecked(place)"
              @change="onChangePlaceType"
            />
            <span class="checkbox-icon"></span>
            {{ removeProtocol(place[label]) }}
          </label>
        </div>
        <div class="form-group">
          <div class="form-control-label-wrapper">
            <label class="form-control-label">Ключевые слова</label>
            <Hint>
              <key-words-hint />
            </Hint>
          </div>
          <MultipleInput
            @updateFilter="updateParameter(...arguments, 'keyWords')"
            :placeholder="'Ключевые слова для фильтрации закупок'"
            :isVertical="true"
            :selected-values="formData.keyWords"
            data-v-step="filter-keywords-multiple-input"
          ></MultipleInput>
        </div>
        <div class="form-group">
          <div class="form-control-label-wrapper">
            <label class="form-control-label">Исключения</label>
            <Hint>
              <exclude-words-hint />
            </Hint>
          </div>
          <MultipleInput
            @updateFilter="updateParameter(...arguments, 'excludeWords')"
            :placeholder="'Ключевые слова, которые будут исключены из поиска'"
            :isVertical="true"
            :selected-values="formData.excludeWords"
            data-v-step="filter-exclude-words-multiple-input"
          ></MultipleInput>
        </div>
        <div class="form-group">
          <div class="form-control-label-wrapper">
            <label class="form-control-label">Регион</label>
            <Hint>
              <region-words-hint />
            </Hint>
          </div>
          <MultipleInput
            @updateFilter="updateParameter(...arguments, 'regions')"
            :placeholder="'Укажите интересующие регионы'"
            :isVertical="true"
            :selected-values="formData.regions"
            data-v-step="filter-region-multiple-input"
          ></MultipleInput>
        </div>
        <div class="form-group">
          <label class="form-control-label">Название организации</label>
          <MultipleInput
            @updateFilter="updateParameter(...arguments, 'organizations')"
            :placeholder="'Введите ключевые слова для фильтрации организаций'"
            :isVertical="true"
            :selected-values="formData.organizations"
          ></MultipleInput>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="form-control-label">Вид закупки</label>
              <EntitySelect
                :value="formData.purchasesTypes"
                :entities="purchasesTypes"
                :multiple="true"
                :placeholder="'Все'"
                :is-updatable="true"
                @onChange="onChangePurchaseType"
                @onLoad="getProceduresTypes"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-control-label">Отрасль</label>
              <EntitySelect
                :value="formData.sectorsTypes"
                :entities="sectorsTypes"
                :multiple="true"
                :placeholder="'Все'"
                :is-updatable="true"
                @onChange="onChangeSectorType"
                @onLoad="getSectorsTypes"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <div class="form-control-label-wrapper">
                <label class="form-control-label">Код ОКРБ</label>
                <Hint class="hint-okrb">
                  <div class="hint-content" style="min-width: 300px;">
                    <p>
                      Код ОКРБ имеет специальный формат ХХ.ХХ.ХХ.ХХХ и отличается в
                      зависимости от группы товаров
                    </p>
                  </div>
                </Hint>
              </div>
              <MultipleInput
                @updateFilter="updateParameter(...arguments, 'codesOKRB')"
                :placeholder="'Коды ОКРБ'"
                :isVertical="true"
                :inputmask="{
                  mask: '99[.99][.99][.999]',
                  greedy: false,
                  placeholder: '',
                  showMaskOnHover: false,
                }"
                :selected-values="formData.codesOKRB"
              ></MultipleInput>
            </div>
          </div>
        </div>

        <transition name="animate-notification">


        <div v-if="formData.notificationEnabled" class="form-group">
          <div class="form-control-label-wrapper">
            <label class="form-control-label email-title"
              >Email уведомления о новых тендерах</label
            >
          </div>
          <div class="form-control-checkbox">
            <label
              v-for="(day, index) in daysOfWeek"
              :key="index"
              class="checkbox-day"
            >
              <input
                :value="formData.daysNotification"
                type="checkbox"
                :checked="selectedDays.includes(day)"
                @change="toggleDay(day, $event)"
              />
              <span>{{ day }}</span>
            </label>
          </div>
          <div class="form-control-chips-time">
            <label class="form-control-label time-position"
              >Время получения</label
            >
            <TimePicker
              @updateTime="updateParameter(...arguments, 'timeNotification')"
              :value="formData.timeNotification"
              :selectedTimes="formData.timeNotification"
            />
          </div>
          <div
            v-for="(email, index) in emails"
            :key="index"
            class="form-control-email"
          >
            <input
              class="email-item form-control"
              ref="emailNotification"
              type="email"
              placeholder="Введите Email"
              v-model.trim="emails[index]"
              @input="updateEmailNotification"
              :class="{ 'is-invalid': $v.formData.emails.$error }"
              required
            />
            <div
              v-if="
                $v.formData.emails.$dirty &&
                !$v.formData.emails.email
              "
              class="invalid-feedback"
            >
              Некорректный E-mail
            </div>
            <div
              v-if="
                $v.formData.emails.$dirty &&
                !$v.formData.emails.maxLength
              "
              class="invalid-feedback"
            >
              Поле должно содержать не более
              {{ $v.formData.emails.$params.maxLength.max }} символов
            </div>
            <button
              type="button"
              v-if="index === 0"
              @click="addEmailField"
              class="add-email-btn"
              :disabled="emails.length > 1"
            >
              <img
                src="../../assets/img/add-btn.svg"
                alt="add"
                :class="{ 'icon-disabled': emails.length > 1 }"
              />
            </button>
            <button
              type="button"
              v-if="index > 0"
              @click="removeEmailField(index)"
              class="delete-icon"
            >
              <img src="../../assets/img/icon-trash.svg" alt="delete" />
            </button>
          </div>
        </div>
      </transition>
      </div>
      <div class="form-footer form-footer-fixed">
        <ToggleShowEmail
          :is-email-shown="formData.notificationEnabled"
          @change="updateParameter($event, 'notificationEnabled')"
        />
        <div class="btn-group">
          <template>
            <div class="toolTip-container"
              @mouseenter="showTooltip = true"
              @mouseleave="showTooltip = false">
              <button
                class="btn btn-middle btn-copy"
                type="button"
                :disabled="isDisabled"
                :title="isDisabled ? 'Введите название фильтра' : ''"
                @click="copyFilter()"
              >
                <img src="../../assets/img/icon-copy.svg">
              </button>
              <transition name="fade">
                <div class="tooltip"
                :class="{ show: showTooltip && !isDisabled }"
                >Сделать копию фильтра</div>
              </transition>
            </div>
          </template>
          <button
            class="btn btn-middle"
            style="width: 160px;"
            type="button"
            :disabled="isDisabled"
            :title="isDisabled ? 'Введите название фильтра' : ''"
            @click="
              componentProps.mode === 'Create' ? createFilter() : editFilter()
            "
            data-v-step="create-filter-confirm-button"
          >
          Сохранить
        </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import Hint from "@/components/common/Hint";
import MultipleInput from "@/components/purchasesTableCustomFilter/MultipleInput";
import { mapActions, mapGetters } from "vuex";
import EntitySelect from "../common/EntitySelect";
import ErrorMessage from "@/components/common/ErrorMessage";
import { generateTabFilters } from "@/utils/rsql";
import { and } from "rsql-builder";
import { createFilterData } from "@/utils/mapper";
import { purchasesPath } from "@/router/purchases";
import KeyWordsHint from "../hints/KeyWordsHint";
import ExcludeWordsHint from "../hints/ExcludeWordsHint";
import RegionWordsHint from "../hints/RegionWordsHint";
import ToggleShowEmail from "../purchasesTableSettings/ToggleShowEmail.vue";
import TimePicker from "../timePicker/TimePicker.vue";
import { convertDaysToEnglish, convertDaysToRussian } from "../../utils/converter-days";
import { sendNotificationEmail } from "../../application/requests/notificationsRequests";
import { PurchasesTableEditCustomFilterModalProperties } from "@/utils/modalsProperties";
import PurchasesTableCustomFilterModal from "@/components/purchasesTableCustomFilter/PurchasesTableCustomFilterModal";

export default {
  name: "purchasesTableCustomFilterModal",
  props: ["componentProps"],
  data() {
    return {
      formData: {
        placesTypes: [],
        notificationEnabled: false,
        daysNotification: [],
        timeNotification: [],
        emailNotification: [],
      },
      isBudgetInvalid: false,
      errorMessage: null,
      daysOfWeek: ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"],
      selectedDays: ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
      isEmailShown: false,
      emails: [''],
      trackBy: 'id',
      label: 'value',
      showTooltip: false,
      isCopying: false,
      copyError: null,
      isInitializing: true
    };
  },

  components: {
    RegionWordsHint,
    ExcludeWordsHint,
    KeyWordsHint,
    EntitySelect,
    Hint,
    MultipleInput,
    ErrorMessage,
    ToggleShowEmail,
    TimePicker,
  },

  validations: {
    formData: {
      name: { required },
      emails: {
        required: function() {
          return this.formData.notificationEnabled ? required : {};
        },
        $each: {
          email: function(value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(value);
          },
          maxLength: maxLength(50),
        },
      }
    },
  },

  computed: {
    ...mapGetters("enums", ["purchasesTypes", "placesTypes", "sectorsTypes"]),
    ...mapGetters("profile", ["profileId", "hintsEnabled"]),
    ...mapGetters("filterTabs", ["filterTabByIndex", "filterTabByParam"]),
    ...mapGetters("purchasesTableSettings", ["isKeywordShown"]),
    isDisabled: function () {
      return !this.formData.name;
    }
  },

  watch: {
    'formData.notificationEnabled': function(value) {
      if (this.isInitializing) return;
      this.$nextTick(() => {
        value ? this.scrollToDown() : this.scrollToTop();
      });
    },

    placesTypes: {
      handler(newVal) {
        if (!this.formData.placesTypes?.length && Array.isArray(newVal) && newVal.length > 0) {
          this.$nextTick(() => {
            if (!this.formData.placesTypes?.length) {
            this.formData.placesTypes = [...newVal];
          }
          });
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions("filterTabs", [
      "createFilterTab",
      "getFilterTabs",
      "setCurrentTab",
      "editFilterTab",
    ]),
    ...mapActions("enums", [
      "getProceduresTypes",
      "getPlacesTypes",
      "getSectorsTypes",
    ]),

    removeSpaces(event) {
      if (event.target.value.charAt(0) === " ") {
        event.target.value = "";
      }
    },

    removeProtocol(url) {
      return url.replace(/^https?:\/\//, '');
    },

    compareBudget() {
      const budgetFrom = this.formData.budgetFrom;
      const budgetTo = this.formData.budgetTo;
      if (budgetFrom && budgetTo && Number(budgetFrom) > Number(budgetTo)) {
        this.isBudgetInvalid = true;
        return true;
      }
    },

    isChecked(place) {
        if (!Array.isArray(this.formData.placesTypes)) {
        return false;
      }
      return this.formData.placesTypes.some(
        (item) => item[this.trackBy] === place[this.trackBy]
      );
    },

    scrollToTop() {
        if (this.$refs.content) {
          const element = this.$refs.content;
          element.style.scrollBehavior = 'smooth';
          element.scrollTop = 0;

          setTimeout(() => {
            element.style.scrollBehavior = 'auto';
          }, 500);
        }

    },

    scrollToDown() {
        if (this.$refs.content) {
          const element = this.$refs.content;
          element.style.scrollBehavior = 'smooth';
          element.scrollTop = element.scrollHeight;

          setTimeout(() => {
            element.style.scrollBehavior = 'auto';
          }, 500);
        }
    },

    updateParameter(values, field) {
      this.formData = {
        ...this.formData,
        [field]: values,
      };
    },

    async createFilter() {
      if (this.$v.$invalid || this.compareBudget()) {
        this.$v.$touch();
        this.scrollToTop();
        return;
      }

      if (!this.validateEmails()) {
        this.scrollToTop();
        return;
      }

      if (!Array.isArray(this.formData.timeNotification) || !this.formData.timeNotification.length) {
        this.formData.timeNotification = ["09:00"];
      }

      this.updateEmailNotification();

      const filterData = createFilterData(this.formData);
      filterData.profile = `/${this.profileId}`;
      filterData.searchQuery = and(
        ...generateTabFilters(filterData.searchCriteria)
      );

      try {
        const response = await this.createFilterTab(filterData);
        await this.getFilterTabs(this.profileId);
        const filterTabByIndex = this.filterTabByIndex(3);
        this.$router.push(`${purchasesPath}/${filterTabByIndex.id}`);
        this.setCurrentTab(filterTabByIndex);

        if (this.formData.notificationEnabled) {
          const notificationEmails =
            this.formData.emailNotification || this.emails;

          const notificationData = {
            notificationDays: convertDaysToEnglish(
              this.formData.daysNotification.filter(day => this.selectedDays.includes(day))
            ),
            notificationTimes: this.formData.timeNotification,
            notificationEmails: notificationEmails.filter((email) => email),
          };

          await sendNotificationEmail(response.data.id, notificationData);
        }
        if (this.$tours["startTour"].currentStep === 5) {
          this.$tours["startTour"].nextStep();
        }
        this.$modal.close();
      } catch (e) {
        this.errorMessage = e.message;
        this.scrollToTop();
      }
    },

    async editFilter() {
      if (this.$v.$invalid || this.compareBudget()) {
        this.$v.$touch();
        this.scrollToTop();
        return;
      }

      if (!this.validateEmails()) {
        this.scrollToTop();
        return;
      }

      const filterData = createFilterData(this.formData);
      filterData.searchQuery = and(
        ...generateTabFilters(filterData.searchCriteria)
      );

      try {
        if (this.formData.notificationEnabled) {
          const notificationEmails = (this.formData.emailNotification ?? this.emails) || [];
          const notificationDays = this.formData.daysNotification ? convertDaysToEnglish(
            this.formData.daysNotification.filter(day => this.selectedDays.includes(day))
          )
          : this.formData.daysWeek;

          const notificationData = {
            notificationDays: notificationDays,
            notificationTimes: this.formData.timeNotification,
            notificationEmails: notificationEmails.filter((email) => email),
          };

          await sendNotificationEmail(this.formData.id, notificationData);
        }

        await this.editFilterTab({ filterId: this.formData.id, filterData });

        const tab = this.filterTabByParam("id", this.formData.id);

        if (parseInt(this.$route.params.id) === this.formData.id) {
          this.setCurrentTab(tab);
          this.$modal.close();
          return;
        }

        this.$router.push(`${purchasesPath}/${this.formData.id}`);
        this.setCurrentTab(tab);
        this.$modal.close();
      } catch (e) {
        console.error("Error occurred:", e);
        this.errorMessage = e.message;
        this.scrollToTop();
      }
    },

    async copyFilter() {
      if (this.isDisabled || this.isCopying) return;

      this.isCopying = true;
      this.copyError = null;

      const originalFormData = JSON.parse(JSON.stringify(this.formData));

      try {
        if (this.componentProps.mode === 'Create') {
          await this.createFilter();
        } else {
          await this.editFilter();
        }

        await this.$nextTick();

        const copiedFormData = {
          ...originalFormData,
          name: `КОПИЯ_${originalFormData.name}`,
          id: null,
          createdAt: new Date().toISOString()
        };

        setTimeout(() => {
          this.$modal.show(
            PurchasesTableCustomFilterModal,
            {
              formData: copiedFormData,
              mode: 'Create'
            },
            {
              ...PurchasesTableEditCustomFilterModalProperties
            }
          );
        }, 1000);

      } catch (error) {
        this.$toast.error('Не удалось создать копию фильтра');
      } finally {
        this.isCopying = false;
      }
    },

    onChangePurchaseType(value) {
      this.updateParameter(value, "purchasesTypes");
    },

    onChangePlaceType(event) {
      const id = event.target.value;
      const isChecked = event.target.checked;

      if (!Array.isArray(this.formData.placesTypes)) {
        this.formData.placesTypes = [];
      }

      if (isChecked) {
        const selectedPlace = this.placesTypes.find(place => place.id === parseInt(id));
        if (selectedPlace) {
          this.formData.placesTypes.push(selectedPlace);
        }
      } else {
        if (this.formData.placesTypes.length > 1) {
          this.formData.placesTypes = this.formData.placesTypes.filter(place => place.id !== parseInt(id));
        } else {
          event.target.checked = true;
        }
      }
    },

    onChangeSectorType(value) {
      this.updateParameter(value, "sectorsTypes");
    },

    isActiveEmailForm() {
      this.formData.notificationEnabled = !this.formData.notificationEnabled;
      this.initializeNotificationFields();
      this.$v.$reset();
    },

    addEmailField() {
      if (this.emails.length < 2) {
        this.emails.push("");
        this.isAddDisabled = true;
        this.updateEmailNotification();
      }
    },

    removeEmailField(index) {
      if (index > 0) {
        this.emails.splice(index, 1);
        this.updateEmailNotification();
      }
    },

    updateEmailNotification() {
      this.formData.daysNotification = [...this.daysOfWeek];
      this.formData.emailNotification = [...this.emails];
    },

    toggleDay(day, event) {
      const index = this.selectedDays.indexOf(day);

      if (index > -1 && this.selectedDays.length === 1) {
        event.target.checked = true;
        return;
      }
      if (index > -1) {
        this.selectedDays.splice(index, 1);
      } else {
        this.selectedDays.push(day);
      }

      this.formData.daysNotification = [...this.selectedDays];
    },

    initializeFieldsNotification() {
      if (this.formData.notificationEnabled) {
        this.selectedDays = Array.isArray(this.formData.daysWeek) ? convertDaysToRussian(this.formData.daysWeek) : [];
        this.emails = Array.isArray(this.formData.emailNotification) ? [...this.formData.emailNotification] : [''];
        this.formData.timeNotification = Array.isArray(this.formData.timeNotification) ? [...this.formData.timeNotification] : [];
      } else {
        this.selectedDays = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
        this.emails = [''];
        this.formData.timeNotification = ['09:00'];
      }
    },

    validateEmails() {
        const invalidEmails = this.emails.filter((email) => {
        const trimmedEmail = email?.trim();
        const emailValidation =
          trimmedEmail &&
          /^(?!.*\.\.)(?!.*\.$)[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedEmail) &&
          !/\s/.test(trimmedEmail);
        return !emailValidation;
      });

      const tooLongEmails = this.emails.filter((email) => {
        const trimmedEmail = email?.trim();
        return trimmedEmail && trimmedEmail.length > 50;
      });

      if (this.formData.notificationEnabled && (invalidEmails.length > 0 || tooLongEmails.length > 0)) {
        this.errorMessage =
          invalidEmails.length > 0
            ? "Некорректный E-mail"
            : "Поле E-mail должно содержать не более 50 символов";
        return false;
      }
      return true;
    },
  },

  created() {
    this.getPlacesTypes();
    this.formData = { ...this.componentProps.formData };
    this.initializeFieldsNotification();
  },

  mounted() {
    this.$nextTick(() => {
      this.scrollToTop();
      this.isInitializing = false;
    });
    if (!this.$tours["startTour"].isRunning && this.hintsEnabled) {
      this.$tours["startTour"].start("1");
    }
    this.$refs["filterNameInput"].focus();
  },
};
</script>

<style lang="less">
@import "../../assets/less/variables.less";
@import "../../assets/less/bootstrapGrid.less";
@import "../../assets/less/commonStyles.less";

.budget {
  position: relative;

  &::after {
    content: "-";
    position: absolute;
    right: -2px;
    top: 14px;
    z-index: 1;
    color: @bg-color;
  }
}


.animate-notification-enter,
    .animate-notification-leave-active {
        opacity: 0;
    }

    .animate-notification-enter-active {
        transition: all .1s ease;
        opacity: 1;
    }

    .animate-notification-leave-active {
        transition: all .2s ease;
        opacity: 0;
    }

.row {
  display: flex;
  justify-content: center;

  .col-6 {
    flex: 1;
    min-width: 0;
  }
}

.email-title {
  margin: 10px 0;
}

.form-control-checkbox {
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;
  gap: 5px;
  margin: 15px 0 5px 0;

  .checkbox-day {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .checkbox-day input[type="checkbox"] {
    display: none;
  }

  .checkbox-day span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 32px;
    padding: 5px 15px;
    border: 1px solid #cfd8dd;
    border-radius: 4px;
    background-color: transparent;
    transition: background-color 0.3s;
  }

  .checkbox-day input[type="checkbox"]:checked + span {
    background-color: #7d919b;
    color: #fff;
  }
}

.form-control-chips-time {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;

  .time-position {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }

  .filters-container {
    border: none;
  }
}

.form-control-email {
  display: flex;
  gap: 5px;

  .email-item {
    width: 420px;
    height: 40px;
    margin-bottom: 5px;
    padding: 0 24px 0 16px;
    border-radius: 3px;
    border: 2px solid #cfd8dd;
  }

  .icon-disabled {
    opacity: 0.5;
    cursor: auto;
  }
}

.form-places {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  flex-wrap: wrap;
  padding: 10px 0 15px 0;
  row-gap: 10px;

  align-items: center;
  justify-content:flex-start;

  .places-item {
    display: flex;
    padding: 5px 0;
    width: 180px;
    gap: 5px;
    align-items: center;
    cursor: pointer;

    input[type="checkbox"] {
      display: none;
    }

    .checkbox-icon {
      width: 20px;
      height: 20px;
      background-image: url("../../assets/img/check-off.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      flex-shrink: 0;
      transition: background-image 0.2s ease, opacity 0.2s ease;
    }

    input[type="checkbox"]:checked + .checkbox-icon {
      background-image: url("../../assets/img/check-on.svg");
      opacity: 1;
    }
  }
}

.hint-okrb {
    .hint {
      position: absolute;
      top: -50px;
      right: 100%;
      min-height: 70px;
    }
}

.btn-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: relative;


  .btn-copy {
    width: 48px;
    height: 48px;
  }
}

.toolTip-container {
  position: relative;
  display: inline-block;

  .tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-85%);
    background-color: #7D919B;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    margin-bottom: 8px;
    opacity: 0;
    transition: opacity 0.4s ease;
    pointer-events: none;
  }

  .tooltip.show {
    opacity: .9;
  }
}
</style>
